import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/authConfig';
import { FaArrowRight } from 'react-icons/fa';
import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const RealSignUp = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const handleLoginWithMicrosoft = async (event) => {
    event.preventDefault();
    if (isAuthenticated) {
      navigate('/threads');
      return;
    }
    try {
      await instance.loginRedirect({
        ...loginRequest,
        redirectStartPage: '/threads/',
      });
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="relative min-h-full h-[100vh] flex items-center justify-center">
      {/* Background video */}
      <video className="absolute top-0 left-0 w-full h-full object-cover z-0" autoPlay loop muted>
        <source src="/bg_video2.mp4" type="video/mp4" />
      </video>
      {/* Content */}
      <div className="sm:mx-auto sm:w-full sm:max-w-sm z-10 mt-96">
        <div className="mt-10">
          <form onSubmit={handleLoginWithMicrosoft} className="space-y-6">
            <button
              type="submit"
              className="flex w-full justify-center mb-8 rounded-full bg-black px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm"
            >
              Welcome to Elysium
              <FaArrowRight className="ml-2 mt-1.5" />
            </button>
          </form>
          <div>
            <div className="text-white text-[14px] font-normal mt-2 text-center">Powered by</div>
            <img alt="Your Company" src="/image.png" className="mx-auto h-[96px] w-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealSignUp;
