import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxiosInstance from '../utils/axiosInstance';
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4

const ChatContext = createContext();

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatContextProvider');
  }
  return context;
};

export const ChatContextProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [defaultThread, setDefaultThread] = useState(null);
  const [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
  const [user, setUser] = useState({ username: 'User', title: 'Member' }); // Default values
  const axiosInstance = useAxiosInstance();

  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get('/api/users/me/');
      const { username, title } = response.data;
      setUser({ username, title });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const clearMessages = () => setMessages([]);

  const generateInitialGreeting = (threadId) => {
    const existingGreeting = JSON.parse(localStorage.getItem(`chatMessages_${threadId}`));

    if (
      existingGreeting &&
      existingGreeting.some((msg) => msg.ai && msg.text.includes('how can I assist'))
    ) {
      return; // Avoid creating duplicate greetings
    }

    const greetingMessage = `Hello ${user.username}, as a ${user.title}, how can I assist you today?`;
    const initialMessage = {
      id: uuidv4(), // Use uuidv4 for unique ID
      createdAt: new Date().toISOString(),
      text: greetingMessage,
      ai: true,
    };

    setMessages([initialMessage]);
    setDefaultThread(threadId);
    localStorage.setItem(`chatMessages_${threadId}`, JSON.stringify([initialMessage]));
  };

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        clearMessages,
        defaultThread,
        setDefaultThread,
        isGeneratingAnswer,
        setIsGeneratingAnswer,
        generateInitialGreeting,
        user,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
