import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import person from '../assets/person.png';
import mindhyveLogo from '../assets/mindhyveLogo.png';
import useAxiosInstance from '../utils/axiosInstance.js';

const ChatMessage = ({ message, chatContainerRef, isLastMessage }) => {
  const { text, ai, createdAt } = message;
  const isHuman = !ai;
  const [references, setReferences] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredReference, setHoveredReference] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [loadingReferences, setLoadingReferences] = useState(false);
  const [noReferencesFound, setNoReferencesFound] = useState(false);
  const dropdownRef = useRef(null);
  const axiosInstance = useAxiosInstance();

  const loadReferences = useCallback(async () => {
    if (showDropdown) {
      setShowDropdown(false);
      return;
    }

    setLoadingReferences(true);
    setNoReferencesFound(false);
    try {
      const response = await axiosInstance.post('/api/search-documents/', {
        search_query: text,
      });
      if (response.data && response.data.length > 0) {
        setReferences(response.data);
        setShowDropdown(true);
      } else {
        setNoReferencesFound(true);
      }
    } catch (error) {
      console.error('Error loading references:', error);
      setNoReferencesFound(true);
    } finally {
      setLoadingReferences(false);
    }
  }, [showDropdown, text, axiosInstance]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setShowCard(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseEnterOption = (ref) => {
    setHoveredReference(ref);
    setShowCard(true);
  };

  const handleMouseLeaveOption = () => {
    setTimeout(() => {
      if (!showCard) setHoveredReference(null);
    }, 200);
  };

  const handleMouseEnterCard = () => {
    setShowCard(true);
  };

  const handleMouseLeaveCard = () => {
    setShowCard(false);
    setHoveredReference(null);
  };

  // Scroll down the chat container when the hover card opens on the last AI response
  useEffect(() => {
    if (showCard && isLastMessage && ai && chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight, // Scroll to the bottom
        behavior: 'smooth',
      });
    }
  }, [showCard, isLastMessage, ai, chatContainerRef]);

  return (
    <div className={`message-group mb-6 ${isHuman ? 'justify-end' : 'justify-start'} flex w-full`}>
      {isHuman ? (
        <div className="flex justify-end mb-4 w-full max-w-full">
          <div className="message__wrapper max-w-[70%] text-[14px] text-grey rounded-lg p-3">
            <ReactMarkdown className="text-right">{text}</ReactMarkdown>
            <div className="text-xs mt-1 text-gray-500 text-right">
              {moment(createdAt).calendar()}
            </div>
          </div>
          <div className="ml-3">
            <img src={person} alt="User avatar" className="w-8 border rounded-full" />
          </div>
        </div>
      ) : (
        <div className="flex justify-start mb-4 w-full">
          <div className="mr-3">
            <img src={mindhyveLogo} alt="AI avatar" className="w-8 ml-2 border rounded-full" />
          </div>
          <div className="w-[70%] bg-gray-200 text-grey text-[14px] rounded-lg p-3 relative">
            <ReactMarkdown className="text-left">{text}</ReactMarkdown>
            <div className="flex items-center mt-1">
              <div className="text-xs text-gray-500">{moment(createdAt).calendar()}</div>
              {!noReferencesFound ? (
                <button
                  onClick={loadReferences}
                  className="relative text-white text-xs px-2 py-1 rounded flex items-center"
                >
                  {loadingReferences ? (
                    <div className="loading-spinner mr-2" />
                  ) : (
                    <div className="bg-blue-500 rounded px-2 py-1">Load References</div>
                  )}
                </button>
              ) : (
                <div className="text-xs text-gray-500 ml-2">No references found</div>
              )}
            </div>

            {/* Dropdown for references with scrolling after 5 items */}
            {showDropdown && references.length > 0 && (
              <div
                ref={dropdownRef}
                className="absolute bg-white border mt-1 rounded shadow-lg w-64 z-10 max-h-40 overflow-y-auto"
              >
                {references.map((ref, index) => (
                  <div
                    key={index}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onMouseEnter={() => handleMouseEnterOption(ref)}
                    onMouseLeave={handleMouseLeaveOption}
                  >
                    {ref.document_url}
                  </div>
                ))}
              </div>
            )}

            {/* Hover card displaying detailed reference content */}
            {hoveredReference && showCard && (
              <div
                className="absolute bg-white text-[14px] border rounded shadow-lg px-2 py-1 w-90 left-[267px] z-20 max-h-60 overflow-y-auto"
                style={{ top: dropdownRef.current?.offsetTop || '0px' }}
                onMouseEnter={handleMouseEnterCard}
                onMouseLeave={handleMouseLeaveCard}
              >
                <ReactMarkdown>{hoveredReference.document_content}</ReactMarkdown>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
