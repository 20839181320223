import React, { useState, useEffect, useRef } from 'react';
import { useChatContext } from '../context/chatContext';

const Modal2 = ({ modalOpen, setModalOpen, handleSave }) => {
  const [title, setTitle] = useState('');
  const inputRef = useRef(null);
  const { generateInitialGreeting } = useChatContext(); // Import the function

  useEffect(() => {
    if (modalOpen) {
      setTitle('');
      inputRef.current?.focus();
    }
  }, [modalOpen]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && title.trim()) {
      handleSave(title);
      setModalOpen(false);
    }
  };

  const handleSaveClick = () => {
    handleSave(title);
    const threadId = uuidv4(); // Generate new thread ID
    generateInitialGreeting(threadId); // This should now persist
    setModalOpen(false);
  };

  if (!modalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
      <div className="relative bg-white rounded-lg w-[500px] p-6">
        <button
          className="absolute top-2 right-4 text-2xl text-#e5e7eb"
          onClick={() => setModalOpen(false)}
        >
          &times;
        </button>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl text-#e5e7eb font-semibold">Name your thread</h2>
        </div>
        <input
          type="text"
          ref={inputRef}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter chat title"
          className="w-full p-2 mb-4 text-black bg-transparent border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <div className="flex justify-end space-x-2">
          <button
            className="bg-[#1976D2] text-white px-4 py-2 rounded cursor-pointer"
            onClick={handleSaveClick}
            disabled={!title.trim()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
