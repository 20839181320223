import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack,
} from '@mui/material';
import { toast, Toaster } from 'react-hot-toast';
import useAxiosInstance from '../utils/axiosInstance';

const Setting = ({ setModalOpen }) => {
  const axiosInstance = useAxiosInstance();
  const [topP, setTopP] = useState(0.9);
  const [responseType, setResponseType] = useState('accurate');
  const [instructions, setInstructions] = useState('');
  const [settingsId, setSettingsId] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axiosInstance.get('/api/settings/');
        const settings = response.data[0];

        if (settings) {
          const { id, response_type, instructions: savedInstructions } = settings;
          setSettingsId(id);
          setResponseType(response_type || 'accurate');
          setInstructions(savedInstructions || '');

          // Update localStorage after fetching
          localStorage.setItem('responseType', response_type);
          localStorage.setItem('instructions', savedInstructions);
        }
      } catch (error) {
        console.error('Error fetching settings:', error.message || error);
        // Fallback to localStorage if API fails
        setResponseType(localStorage.getItem('responseType') || 'accurate');
        setInstructions(localStorage.getItem('instructions') || '');
      }
    };

    fetchSettings();
  }, []);

  const saveKey = async (e) => {
    e.preventDefault();

    const currentSettings = {
      response_type: localStorage.getItem('responseType'),
      instructions: localStorage.getItem('instructions'),
    };

    if (
      currentSettings.response_type === responseType &&
      currentSettings.instructions === instructions
    ) {
      toast.error('Settings already updated');
      return;
    }

    const data = {
      instructions: instructions,
      response_type: responseType,
    };

    try {
      const response = await axiosInstance.post('api/settings/', data);
      setModalOpen(false);
    } catch (error) {
      console.error('An error occurred while saving settings:', error.message || error);
    }
  };

  const updateSettings = async (e) => {
    e.preventDefault();

    const data = {
      response_type: responseType,
      instructions: instructions,
    };

    try {
      const response = await axiosInstance.patch(`/api/settings/${settingsId}/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': 'kO4pD6cEg3PrQ5HtcuXXQJZU7bYdN8soKWbspTuFf23YxbDGIP8c03pBlXN8dPDf',
        },
      });
      console.log('Settings updated successfully:', response.data);
      setModalOpen(false);
    } catch (error) {
      console.error('An error occurred while updating settings:', error.message || error);
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <form onSubmit={saveKey} className="flex flex-col items-center justify-center gap-2">
        <Box sx={{ width: 300 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel className="-my-2 -ml-3">Response Type</InputLabel>
            <Select value={responseType} onChange={(e) => setResponseType(e.target.value)}>
              <MenuItem value="accurate">Accurate</MenuItem>
              <MenuItem value="creative">Creative</MenuItem>
              <MenuItem value="balanced">Balanced</MenuItem>
            </Select>
          </FormControl>

          <Typography gutterBottom>Instructions</Typography>
          <TextField
            multiline
            rows={4}
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Box>

        <Stack direction="row" spacing={2} sx={{ mt: 2, width: '100%', maxWidth: 300 }}>
          <Button onClick={() => setModalOpen(false)} variant="outlined" color="primary" fullWidth>
            Close
          </Button>
          <Button onClick={updateSettings} variant="contained" color="primary" fullWidth>
            Update
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default Setting;
