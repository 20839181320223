import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { ChatContextProvider } from '../context/chatContext';
import ChatView from './ChatView';
import Modal from './Modal';
import Setting from './Setting';

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate for redirecting

  useEffect(() => {
    const isLoggedIn = window.localStorage.getItem('isLoggedIn');

    // If 'isLoggedIn' is not true, redirect to the login page
    if (isLoggedIn !== 'true') {
      navigate('/login');
    }
  }, [navigate]); // Add navigate as a dependency

  return (
    <ChatContextProvider>
      <div className="flex h-screen overflow-hidden">
        <div className="flex-1 overflow-hidden">
          <ChatView />
        </div>
      </div>
      <Modal title="Setting" modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <Setting setModalOpen={setModalOpen} />
      </Modal>
    </ChatContextProvider>
  );
};

export default Home;
