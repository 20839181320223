import React, { useState, useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import { useChatContext } from '../context/chatContext';
import { MdSend, MdUpload, MdAttachFile, MdClose } from 'react-icons/md';
import { useParams, useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import useAxiosInstance from '../utils/axiosInstance.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { flushSync } from 'react-dom';

const ChatView = () => {
  const messagesEndRef = useRef();
  const chatContainerRef = useRef();
  const fileInputRef = useRef(null);
  const axiosInstance = useAxiosInstance();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState('');
  const { messages, setMessages, defaultThread, isGeneratingAnswer, setIsGeneratingAnswer } =
    useChatContext();
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [isUserNearBottom, setIsUserNearBottom] = useState(true);
  const { threadId } = useParams();
  const navigate = useNavigate();

  const fetchChats = async (threadId) => {
    const localMessages = JSON.parse(localStorage.getItem(`chatMessages_${threadId}`));
    if (localMessages) {
      setMessages(localMessages);
    } else {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/threads/${threadId}`,
        );
        if (response.data && Array.isArray(response.data.chats)) {
          setMessages(response.data.chats);
          localStorage.setItem(`chatMessages_${threadId}`, JSON.stringify(response.data.chats));
        }
      } catch (error) {
        console.error('Error fetching chats:', error.response?.data || error.message);
      }
    }
  };

  const checkIfUserIsNearBottom = () => {
    const container = chatContainerRef.current;
    const isNearBottom =
      container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
    setIsUserNearBottom(isNearBottom);
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    container.addEventListener('scroll', checkIfUserIsNearBottom);
    return () => container.removeEventListener('scroll', checkIfUserIsNearBottom);
  }, []);

  useEffect(() => {
    if (isGeneratingAnswer) {
      scrollToBottom();
    }
  }, [messages, isGeneratingAnswer]);

  const updateMessage = (text, isAI = false) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: Date.now(),
        createdAt: new Date().toISOString(),
        text,
        ai: isAI,
      },
    ]);
  };

  const scrollToBottom = (force = false) => {
    if (force || isUserNearBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const sendMessageToAPI = async (messageToSend, threadId) => {
    try {
      setIsGeneratingAnswer(true);

      const accessTokenKey = Object.keys(sessionStorage).find((key) => key.includes('accesstoken'));
      const sessionData = JSON.parse(sessionStorage.getItem(accessTokenKey));
      const bearerToken = sessionData;

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/stream/?message=${encodeURIComponent(
        messageToSend,
      )}&thread_id=${threadId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'text/plain',
        },
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`Failed to get response from the API: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      // Initialize AI message block and immediately scroll to bottom
      flushSync(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: Date.now(),
            createdAt: new Date().toISOString(),
            text: '', // Start with an empty response block
            ai: true,
          },
        ]);
      });

      // Scroll to bring the full block immediately into view
      scrollToBottom(true);

      const streamMessages = async () => {
        let done = false;
        let accumulatedWord = '';

        while (!done) {
          const { done: isDone, value } = await reader.read();
          done = isDone;

          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            const words = chunk.split(/\s+/);

            for (let word of words) {
              accumulatedWord += word + ' ';

              await new Promise((resolve) =>
                setTimeout(resolve, Math.floor(Math.random() * 10) + 10),
              );

              flushSync(() => {
                setMessages((prevMessages) => {
                  const updatedMessages = [...prevMessages];
                  updatedMessages[updatedMessages.length - 1].text = accumulatedWord.trim();
                  return updatedMessages;
                });
              });
            }
          }
        }
      };

      await streamMessages();
    } catch (error) {
      console.error('Error sending message to API:', error.message);
      updateMessage(error.message, true);
    } finally {
      setIsGeneratingAnswer(false);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!formValue.trim()) return;

    const cleanPrompt = formValue.trim();
    setFormValue('');
    updateMessage(cleanPrompt, false);

    await sendMessageToAPI(cleanPrompt, threadId);
  };

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'text/plain',
    ];
    const validFiles = files.filter((file) => allowedTypes.includes(file.type));

    if (validFiles.length === files.length) {
      setUploadError(null);
      for (const file of validFiles) {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await axiosInstance.post('/api/documents/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          console.log('File uploaded successfully:', response.data);
        } catch (error) {
          console.error('Error uploading file:', error);
          setUploadError('Failed to upload some files.');
        }
      }
      fileInputRef.current.value = null;
    } else {
      setUploadError('Only .docx, .xlsx, .pdf, and .txt files are allowed.');
    }
  };

  const removeFile = (id) => {
    setAttachedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (threadId) {
      setMessages([]);
      fetchChats(threadId);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [threadId]);

  const exportAsPDF = async () => {
    const element = chatContainerRef.current;
    const clonedElement = element.cloneNode(true);
    clonedElement.style.position = 'absolute';
    clonedElement.style.top = '-9999px';
    clonedElement.style.left = '-9999px';
    clonedElement.style.height = 'auto';
    clonedElement.style.overflow = 'visible';
    clonedElement.style.fontSize = '28px';
    document.body.appendChild(clonedElement);

    try {
      const canvas = await html2canvas(clonedElement, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        logging: true,
        windowWidth: document.documentElement.clientWidth,
        windowHeight: clonedElement.scrollHeight,
      });

      const imageData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      if (pdfHeight > pdf.internal.pageSize.getHeight()) {
        const totalPages = Math.ceil(pdfHeight / pdf.internal.pageSize.getHeight());

        for (let i = 0; i < totalPages; i++) {
          if (i > 0) pdf.addPage();
          const yOffset = -pdf.internal.pageSize.getHeight() * i;
          pdf.addImage(imageData, 'PNG', 0, yOffset, pdfWidth, pdfHeight);
        }
      } else {
        pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      }

      pdf.save(`chat_${threadId || 'export'}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      document.body.removeChild(clonedElement);
    }
  };

  return (
    <div className="flex">
      <SideBar />
      <div className="chatview flex-grow w-[70%]">
        <main className="chatview__chatarea" style={{ paddingTop: '40px' }} ref={chatContainerRef}>
          {messages &&
            messages.map((message, index) => (
              <ChatMessage
                key={message.id || index}
                message={message}
                chatContainerRef={chatContainerRef}
                isLastMessage={index === messages.length - 1 && message.ai}
              />
            ))}
          <span ref={messagesEndRef}></span>
        </main>
        <form className="form" onSubmit={sendMessage}>
          <div className="relative w-full">
            <div className="attached-files flex flex-wrap space-x-2 my-2">
              {attachedFiles.map((file) => (
                <div key={file.id} className="relative w-20 h-20 flex-shrink-0">
                  {file.type.startsWith('image/') ? (
                    <img
                      src={file.url}
                      alt={file.name}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <div className="file-preview w-full h-full bg-gray-100 flex items-center justify-center rounded-lg p-2 border border-gray-300">
                      <span className="text-xs text-center truncate px-1">{file.name}</span>
                    </div>
                  )}
                  <button
                    className="absolute -top-2 -right-2 bg-red-500 text-white p-1 rounded-full"
                    onClick={() => removeFile(file.id)}
                  >
                    <MdClose size={16} />
                  </button>
                </div>
              ))}
            </div>
            <div className="flex items-center relative">
              <textarea
                ref={inputRef}
                className={`chatview__textarea-message pl-7 mt-1 ${
                  !defaultThread ? 'cursor-not-allowed' : ''
                }`}
                rows={1}
                style={{ height: 'auto', minHeight: '50px', maxHeight: '150px' }}
                value={formValue}
                onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage(e)}
                onChange={(e) => setFormValue(e.target.value)}
                disabled={!defaultThread || isGeneratingAnswer}
              />

              <button
                type="submit"
                className={`chatview__btn-send ${
                  !formValue || isGeneratingAnswer || !defaultThread ? 'cursor-not-allowed' : ''
                }`}
              >
                {isGeneratingAnswer ? <div className="loading-spinner" /> : <MdSend size={30} />}
              </button>

              <button
                type="button"
                className={`chatview__btn-send ${
                  isGeneratingAnswer || !messages.length ? 'cursor-not-allowed' : ''
                }`}
                title="Export as PDF."
                onClick={exportAsPDF}
              >
                {isGeneratingAnswer ? <div className="loading-spinner" /> : <MdUpload size={30} />}
              </button>
              <MdAttachFile
                size={24}
                onClick={() => fileInputRef.current.click()}
                style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div>
              <input
                type="file"
                id="fileUpload"
                accept=".docx, .xlsx, .pdf, .txt"
                ref={fileInputRef}
                multiple
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
            </div>
            {uploadError && <div className="text-red-500 mt-2 text-xs">{uploadError}</div>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatView;
