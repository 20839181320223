import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
// import { InteractionType } from "@azure/msal-browser";
// import { loginRequest } from "./config/authConfig";
import { ChatContextProvider } from './context/chatContext';
import ChatView from './components/ChatView';
import RealSignUp from './components/RealSignUp';
import Home from './components/Home';
import NotFound from './components/NotFound';

function App() {
  return (
    <ChatContextProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Navigate to="/threads" replace />} />
          <Route
            path="/threads/:threadId"
            element={
              <AuthenticatedTemplate>
                <ChatView />
              </AuthenticatedTemplate>
            }
          >
            <Route
              path=""
              element={
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              }
            />
          </Route>
          <Route
            path="/threads/"
            element={
              <>
                <AuthenticatedTemplate>
                  <ChatView />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            }
          />
          <Route
            path="/home"
            element={
              <>
                <AuthenticatedTemplate>
                  <Home />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <UnauthenticatedTemplate>
                  <RealSignUp />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <RealSignUp />
                </AuthenticatedTemplate>
              </>
            }
          />
          {/* Fallback route for unmatched paths */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ChatContextProvider>
  );
}

export default App;
