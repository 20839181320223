const currentHost = window.location.origin; // Use current window location as the base

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID, // Your Azure client ID from environment variables
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`, // Tenant ID from environment variables
    redirectUri: `${currentHost}/threads`, // Use the current host dynamically for redirect URI
  },
  cache: {
    cacheLocation: 'localStorage', // or "sessionStorage"
    storeAuthStateInCookie: true, // Use cookies to store auth state
  },
};

export const loginRequest = {
  scopes: ['User.Read'], // The scope for accessing user information
};
