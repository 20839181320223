import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MdChevronLeft,
  MdChevronRight,
  MdAdd,
  MdOutlineSettings,
  MdDelete,
  MdEdit,
} from 'react-icons/md';
import { debounce } from 'lodash';
import { useChatContext } from '../context/chatContext';
import mindhyveLogo from '../assets/mindhyveLogo.png';
import Modal from './Modal';
import Modal2 from './Modal2';
import Setting from './Setting';
import { Button } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import useAxiosInstance from '../utils/axiosInstance';
import { v4 as uuidv4 } from 'uuid';

const VERSION = '0.1.1';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SideBar = () => {
  const navigate = useNavigate();
  const {
    clearMessages,
    defaultThread,
    setDefaultThread,
    isGeneratingAnswer,
    generateInitialGreeting, // Import the function from context
  } = useChatContext();
  const [open, setOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [newChatModalOpen, setNewChatModalOpen] = useState(false);
  const { instance } = useMsal();
  const axiosInstance = useAxiosInstance();

  const [chats, setChats] = useState([]);
  const chatsRef = useRef(chats);
  const [showChats, setShowChats] = useState(true);
  const [editingChatId, setEditingChatId] = useState(null);
  const [editedChatName, setEditedChatName] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);

  const fetchChats = debounce(async () => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/api/threads/`);
      const sortedChats = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setChats(sortedChats);
      chatsRef.current = sortedChats;

      if (sortedChats.length > 0 && !defaultThread) {
        setDefaultThread(sortedChats[0].thread_id);
        navigate(`/threads/${sortedChats[0].thread_id}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        const randomName = `New Chat ${uuidv4().substr(0, 8)}`;
        try {
          const newThreadResponse = await axiosInstance.post(`${API_BASE_URL}/api/threads/`, {
            thread_name: randomName,
          });
          const newChat = newThreadResponse.data;
          setChats([newChat]);
          chatsRef.current = [newChat];
          setDefaultThread(newChat.thread_id);
          navigate(`/threads/${newChat.thread_id}`);
        } catch (createError) {
          console.error(
            'Error creating new thread:',
            createError.response?.data || createError.message,
          );
        }
      } else {
        console.error('Error fetching chats:', error.response?.data || error.message);
      }
    }
  }, 300);

  useEffect(() => {
    fetchChats();
  }, []);

  const handleChatClick = useCallback(
    (thread_id) => {
      if (!isGeneratingAnswer) {
        setDefaultThread(thread_id);
        navigate(`/threads/${thread_id}`);
      }
    },
    [navigate, setDefaultThread, isGeneratingAnswer],
  );

  const handleNewChat = () => {
    setNewChatModalOpen(true);
  };

  const handleSaveChatTitle = useCallback(
    async (thread_name) => {
      clearMessages();
      setNewChatModalOpen(false);

      try {
        const response = await axiosInstance.post(`${API_BASE_URL}/api/threads/`, { thread_name });
        const newChat = response.data;

        const updatedChats = [newChat, ...chatsRef.current];
        setChats(updatedChats);
        chatsRef.current = updatedChats;

        setDefaultThread(newChat.thread_id);
        navigate(`/threads/${newChat.thread_id}`);

        // Generate initial greeting message for the new thread
        generateInitialGreeting(newChat.thread_id);
      } catch (error) {
        console.error('Error creating thread:', error.response?.data || error.message);
      }
    },
    [axiosInstance, clearMessages, navigate, setDefaultThread, generateInitialGreeting],
  );

  const handleLogoutHome = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin + '/',
      });
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      navigate('/');
    }
  };

  const handleDeleteClick = (e, chat) => {
    e.stopPropagation();
    setChatToDelete(chat);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (chatToDelete) {
      try {
        await axiosInstance.delete(`/api/threads/${chatToDelete.thread_id}/`);
        const updatedChats = chats.filter((chat) => chat.thread_id !== chatToDelete.thread_id);

        setChats(updatedChats);
        chatsRef.current = updatedChats;
        if (updatedChats.length > 0) {
          setDefaultThread(updatedChats[0].thread_id);
          navigate(`/threads/${updatedChats[0].thread_id}`);
        } else {
          setDefaultThread(null);
        }
      } catch (error) {
        console.error('Error deleting chat:', error);
      }
    }
    setDeleteModalOpen(false);
    setChatToDelete(null);
  };

  const handleEditClick = (e, chat) => {
    e.stopPropagation();
    setEditingChatId(chat.thread_id);
    setEditedChatName(chat.thread_name);
  };

  const handleEditChange = (e) => {
    setEditedChatName(e.target.value);
  };

  const handleEditSubmit = async (e, chatId) => {
    e.preventDefault();
    if (editedChatName.trim() === '') return;

    try {
      await axiosInstance.put(`/api/threads/${chatId}/`, {
        thread_name: editedChatName,
      });
      const updatedChats = chats.map((chat) =>
        chat.thread_id === chatId ? { ...chat, thread_name: editedChatName } : chat,
      );
      setChats(updatedChats);
      chatsRef.current = updatedChats;
      setEditingChatId(null);
    } catch (error) {
      console.error('Error updating chat name:', error);
    }
  };

  return (
    <section className="w-[23%] sidebar flex flex-col h-screen bg-white text-black min-w-[64px]">
      <div className="sidebar__app-bar flex items-center justify-between">
        <div className={`sidebar__app-logo ${!open && 'scale-0 hidden'}`}>
          <span className="w-8 h-8">
            <img width="30" src={mindhyveLogo} alt="Logo" />
          </span>
        </div>
        <h1 className={`sidebar__app-title ${!open && 'scale-0 hidden'} mx-auto ml-10`}>
          Elysium.ai
        </h1>
        <div className={'sidebar__btn-close'} onClick={() => setOpen(!open)}>
          {open ? <MdChevronLeft /> : <MdChevronRight />}
        </div>
        <div className="version-display rounded-md inline text-black p-1 bg-fade-white text-xs font-bold">
          Version: {VERSION}
        </div>
      </div>

      <div className="flex-grow overflow-y-auto bg-white">
        {showChats && (
          <>
            <div className="nav mt-2">
              <span
                className="border nav__item border-neutral-600 p-3 w-full text-left cursor-pointer"
                onClick={handleNewChat}
              >
                <div className="nav__icons">
                  <MdAdd />
                </div>
                <h1 className={`${!open && 'hidden'}`}>New chat</h1>
              </span>
            </div>

            <div className="nav flex flex-col space-y-2 mt-2">
              {chats.map((chat) => (
                <div key={chat.thread_id} className="flex items-center">
                  {editingChatId === chat.thread_id ? (
                    <form
                      className="flex-grow flex items-center"
                      onSubmit={(e) => handleEditSubmit(e, chat.thread_id)}
                    >
                      <input
                        type="text"
                        value={editedChatName}
                        onChange={handleEditChange}
                        className="flex-grow border border-neutral-600 p-2 bg-transparent text-black"
                        autoFocus
                      />
                      <button type="submit" className="p-2 text-green-500 hover:text-green-700">
                        Save
                      </button>
                    </form>
                  ) : (
                    <span className="flex items-center justify-between border nav__item border-neutral-600 p-2 w-full text-left cursor-pointer">
                      <span className="flex-grow" onClick={() => handleChatClick(chat.thread_id)}>
                        <h1>{chat.thread_name}</h1>
                      </span>
                      <div className="flex items-center">
                        <button
                          className="p-2 text-blue-500 hover:text-blue-700"
                          onClick={(e) => handleEditClick(e, chat)}
                        >
                          <MdEdit size={20} />
                        </button>
                        <button
                          className="p-2 text-red-500 hover:text-red-700"
                          onClick={(e) => handleDeleteClick(e, chat)}
                        >
                          <MdDelete size={20} />
                        </button>
                      </div>
                    </span>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div className="mt-auto border-t border-neutral-600">
        <div className="flex justify-between items-center p-2">
          <span
            className="nav__item cursor-pointer flex items-center p-2"
            onClick={() => setModalOpen(true)}
          >
            <MdOutlineSettings className="mr-2" />
            <h1 className={`${!open && 'hidden'}`}>Settings</h1>
          </span>

          <Button
            onClick={handleLogoutHome}
            className="nav__item cursor-pointer flex items-center p-2"
            style={{ color: 'inherit', textTransform: 'none' }}
          >
            <h1 className={`${!open && 'hidden'}`}>Logout</h1>
          </Button>
        </div>
      </div>

      <Modal title="Setting" modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <Setting modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </Modal>

      <Modal2
        modalOpen={newChatModalOpen}
        setModalOpen={setNewChatModalOpen}
        handleSave={handleSaveChatTitle}
      />

      <Modal title="Delete Chat" modalOpen={deleteModalOpen} setModalOpen={setDeleteModalOpen}>
        <p>Are you sure you want to delete this chat thread?</p>
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600"
            onClick={() => setDeleteModalOpen(false)}
          >
            No
          </button>
          <button
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
            onClick={handleDeleteConfirm}
          >
            Yes
          </button>
        </div>
      </Modal>
    </section>
  );
};

export default SideBar;
